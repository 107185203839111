import React, { useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tooltip } from '@engyalo/design-system';
import { useTheme } from '@mui/material/styles';
import { managementRoles } from 'constants/roles';
import MenuChatsContext from '../../contexts/menuChats/context';
import ConversationContext from '../../contexts/conversation/context';
import InputSearch from '../InputSearch';
import ChatsContext from '../../contexts/chats/context';
import usePermissions from '../../hooks/usePermissions';

export interface ISearchBar {
  handleChangeSearch: (value: string) => void;
  value: string;
}

const SearchAndStatusBar: React.FC<ISearchBar> = ({ handleChangeSearch, value }: ISearchBar) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    setOpenContactManager,
    setOpenChatsManager,
    setOpenServiceDashboard,
    openChatsManager,
    openContactManager,
    openServiceDashboard,
  } = useContext(MenuChatsContext);
  const { handleStartNewConversation } = useContext(ConversationContext);
  const { handleServiceSelected } = useContext(ChatsContext);
  const [searchedText, setSearchedText] = useState('');
  const { checkPermission } = usePermissions();

  const handleOpenContactManagement = () => {
    handleServiceSelected({});
    setOpenContactManager(() => true);

    if (openChatsManager) {
      setOpenChatsManager(() => false);
    }
    if (openServiceDashboard) {
      setOpenServiceDashboard(() => false);
    }
  };

  const handleOpenChatsManagement = () => {
    handleServiceSelected({});
    setOpenChatsManager(() => true);

    if (openContactManager) {
      setOpenContactManager(() => false);
    }
    if (openServiceDashboard) {
      setOpenServiceDashboard(() => false);
    }
  };

  const handleOpenServiceDashboards = () => {
    handleServiceSelected({});
    setOpenServiceDashboard(() => true);

    if (openChatsManager) {
      setOpenChatsManager(() => false);
    }

    if (openContactManager) {
      setOpenContactManager(() => false);
    }
  };

  const checkPermissionToSeeDashboardAnalytics = () => {
    const show = checkPermission(managementRoles);
    return show;
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '0px 8px',
          gap: '8px',
        }}
        data-testid="searchAndStatusBar"
      >
        <InputSearch
          testId="inputSearchAndStatusbar"
          value={value || searchedText}
          onChange={(inputValue) => {
            handleChangeSearch(inputValue);
            setSearchedText(inputValue);
          }}
          placeholder="search"
        />
        <Tooltip title={`${t('conversationManager')}`} placement="top">
          <IconButton
            data-testid="btnOpenChatsManager"
            variant="outlined"
            color="neutral2"
            onClick={() => handleOpenChatsManagement()}
          >
            <i className="fa-regular fa-clock-rotate-left fa-2xs" />
          </IconButton>
        </Tooltip>

        <Tooltip title={`${t('serviceOverview')}`} placement="top">
          <IconButton
            data-testid="btnOpenServiceDashboard"
            variant="outlined"
            color="neutral2"
            onClick={() => handleOpenServiceDashboards()}
            sx={{ display: checkPermissionToSeeDashboardAnalytics() ? 'flex' : 'none' }}
          >
            <i className="fa-regular fa-chart-user fa-sm" />
          </IconButton>
        </Tooltip>
        <Tooltip title={`${t('contactManager')}`} placement="top">
          <IconButton
            data-testid="btnOpenContactManager"
            variant="outlined"
            color="neutral2"
            onClick={() => handleOpenContactManagement()}
          >
            <i className="fa-regular fa-address-book fa-sm" />
          </IconButton>
        </Tooltip>
      </Stack>

      <Button
        data-testid="btnOpenSendActiveMessage"
        variant="text"
        size="small"
        onClick={() => {
          handleStartNewConversation(true);
          setOpenContactManager(false);
          handleServiceSelected({});
        }}
        startIcon={<i className="fa-regular fa-comment-plus" />}
        sx={{
          height: '68px',
          borderTop: `1px solid ${theme.palette.neutral.var30}`,
          borderBottom: `1px solid ${theme.palette.neutral.var30}`,
          borderRadius: 0,
        }}
      >
        {t('startNewConversation')}
      </Button>
    </>
  );
};

export default SearchAndStatusBar;
