/* eslint no-underscore-dangle: ["error", { "allow": ["_id", "_updatedAt"] }] */
import { DATE_FILTER_OPTIONS } from 'contexts/chatManager/context';
import { NotificationType } from 'contexts/websocket/provider';
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useWebsocket from 'hooks/useWebsocket';
import { useSalesDeskWebsocket } from 'hooks/useSalesDeskWebsocket';
import { OutgoingMessageChatMessage } from '@engyalo/salesdesk-chat-socket-ts-client';
import { RoutingMethods } from 'types/user';
import MenuChatsContext from './context';
import { IAccordeon } from './types';
import ChatsContext from '../chats/context';
import WebsocketContext from '../websocket/context';
import { getChatsFilter } from '../../services/getChatsFilter';
import { IRoom } from '../../types/room';
import AuthContext from '../auth/context';
import { MAX_CHATS_IN_ACCORDION } from '../../constants/defaultValues';
import { getAllAgentRooms } from '../../services/getAllAgentRooms';
import { getInquiries, sortValue } from '../../services/getInquiries';
import { getLivechatRoomInfo } from '../../services/getLivechatRoomInfo';
import { logError } from '../../services/Logger';
import useNotification from '../../hooks/useNotification';
import { flag, useFeatureFlag } from '../../hooks/useFeatureFlag';
import { isTextNotification, shouldNotifyOnNewMessage, updateAccordions } from './utils';

const chatMenuEnabledTriggeringFilters: NotificationType[] = [
  NotificationType.NEW_CHAT,
  NotificationType.CHAT_CLOSED,
  NotificationType.CHAT_TAKEN,
];

const typesTriggeringFilters: NotificationType[] = [...chatMenuEnabledTriggeringFilters, NotificationType.NEW_MESSAGE];

const typesTriggeringDesktopNotifications: NotificationType[] = [
  NotificationType.NEW_CHAT,
  NotificationType.NEW_MESSAGE,
  NotificationType.DISCUSSION,
];

// const typesTriggeringFilters: NotificationType[] = [
//   ...(isSalesDeskChatMenuEnabled ? [] : [NotificationType.NEW_MESSAGE]),
//   NotificationType.NEW_CHAT,
//   NotificationType.CHAT_CLOSED,
//   NotificationType.CHAT_TAKEN,
// ];

const MenuChatsProvider: React.FC = (props: any) => {
  const { children } = props;
  const { t } = useTranslation();
  const { notify } = useNotification();
  const maxChatsInAccordion = useFeatureFlag(flag.LIMIT_CHATS_IN_ACCORDION) ? MAX_CHATS_IN_ACCORDION : undefined;
  const isSalesDeskChatMenuEnabled = useFeatureFlag(flag.SALESDESK_CHAT_MENU_WEBSOCKET);
  const isFIFO = useFeatureFlag(flag.FIFO_INQUIRIES);
  const showOnHold = useFeatureFlag(flag.SHOW_ON_HOLD_METRIC);

  const accordeonsList = [
    {
      id: 'inAttendance',
      title: t('inAttendance'),
      icon: 'comment-dots',
      rooms: [] as any,
      expanded: true,
    },
    {
      id: 'pending',
      title: t('pending'),
      icon: 'comment-exclamation',
      rooms: [] as any,
      expanded: true,
    },
    {
      id: 'onHold',
      title: t('onHold'),
      icon: 'circle-pause',
      rooms: [] as any,
      expanded: false,
    },
    {
      id: 'closedToday',
      title: t('closedToday'),
      icon: 'box-check',
      rooms: [] as any,
      expanded: false,
    },
  ];
  const accordeonsList2 = [
    {
      id: 'inAttendance',
      title: t('inAttendance'),
      icon: 'comment-dots',
      rooms: [] as any,
      expanded: true,
    },
    {
      id: 'pending',
      title: t('pending'),
      icon: 'comment-exclamation',
      rooms: [] as any,
      expanded: true,
    },
    {
      id: 'onHold',
      title: t('onHold'),
      icon: 'circle-pause',
      rooms: [] as any,
      expanded: false,
    },
    {
      id: 'closedToday',
      title: t('closedToday'),
      icon: 'box-check',
      rooms: [] as any,
      expanded: false,
    },
  ];
  const [searchYourChats, setSearchYourChats] = useState<IRoom[]>([]);
  const [searchAllChats, setSearchAllChats] = useState<IRoom[]>([]);
  const [isSearchAllChats, setIsSearchAllChats] = useState(false);
  const [isSearchYourChats, setIsSearchYourChats] = useState(false);
  const [openContactManager, setOpenContactManager] = useState(false);
  const [openServiceDashboard, setOpenServiceDashboard] = useState(false);
  const [openChatsManager, setOpenChatsManager] = useState(false);
  const [openDrawerContactManager, setOpenDrawerContactManager] = useState(false);
  const [currentSearchAllChats, setCurrentSearchAllChats] = useState('');
  const [currentSearchYourChats, setCurrentSearchYourChats] = useState('');
  const [departmentsSelected, setDepartmentsSelected] = useState<string[]>([]);
  const [tagsSelected, setTagsSelected] = useState<string[]>([]);
  const [tagsSelectedYourChats, setTagsSelectedYourChats] = useState<string[]>([]);
  const [agentSelected, setAgentSelected] = useState<string[]>([]);
  const [tooltipSelectedItems, setTooltipSelectedItems] = useState<string[]>([]);
  const [subscribedInquiries, setSubscribedInquiries] = useState<string[]>([]);

  const [agentsNameSelected, setAgentsNameSelected] = useState<string[]>([]);
  const {
    chatId,
    getSubscribesChats,
    subscribeChats,
    serviceSelected,
    handleActiveInput,
    isSelectedItems,
    handleSelectedItems,
    setInAttendanceLength,
  } = useContext(ChatsContext);
  const { notification, loadHistoryMessages, listeningRoomMessages, subscribeNotification, websocketIsConnect } =
    useContext(WebsocketContext);
  const { webSocket: salesDeskWebsocket } = useWebsocket();
  const { salesDeskWebsocketNotification } = useSalesDeskWebsocket({
    websocket: salesDeskWebsocket,
    enabled: isSalesDeskChatMenuEnabled,
  });
  const [accordeonsYourChats, setAccordeonsYourChats] = useState<IAccordeon[]>(accordeonsList);
  const [accordeonsAllChats, setAccordeonsAllChats] = useState<IAccordeon[]>(accordeonsList2);
  const { currentUserInfo, routingMethod } = useContext(AuthContext);
  const [reload, setReload] = useState(false);
  const handleReload = useCallback(() => {
    setReload((prevReload) => !prevReload);
  }, []);

  const handleExpand = useCallback(
    (item: IAccordeon, type: string) => {
      const state = type === 'accordeonsAllChats' ? accordeonsAllChats : accordeonsYourChats;
      const auxAccordeons = state.map((element: any) => {
        if (element.title === item.title) {
          element.expanded = !element.expanded;
        }
        return element;
      });
      const setState = type === 'accordeonsAllChats' ? setAccordeonsAllChats : setAccordeonsYourChats;
      setState(auxAccordeons);
    },
    [accordeonsAllChats, accordeonsYourChats]
  );

  const handleSubscribeNotification = useCallback(() => {
    if (subscribeChats.length > 0) {
      setAccordeonsYourChats((oldState) =>
        oldState.map((element) => {
          if (element.id === 'inAttendance') {
            element.rooms = element.rooms.map((chats) => {
              const subscribe = subscribeChats.find((sub) => sub.rid === chats._id);
              if (subscribe) {
                chats.unreadChat = subscribe.alert;
                chats.unreadMessages = subscribe.unread;
                if (subscribe.lastMessage) {
                  chats.lastMessage = subscribe.lastMessage;
                }
              }
              return chats;
            });
          }
          return element;
        })
      );
    }
  }, [subscribeChats]);

  const handleFilterOptions = useCallback(
    async (searchValue?: string, allAgentRooms?: any) => {
      if (!currentUserInfo._id || !currentUserInfo.departments) return;

      let departmentId;
      if (currentUserInfo.departments.length === 1) {
        departmentId = currentUserInfo.departments[0].departmentId;
      }

      const propsSearchFilterOptions = {
        open: true,
        count: maxChatsInAccordion,
        tags: tagsSelected,
        roomName: searchValue,
        agentIds: agentSelected,
        departmentId,
      };

      const propsSearchFilterOptionsClosedChats = {
        open: false,
        count: maxChatsInAccordion,
        filterDateBy: DATE_FILTER_OPTIONS[1],

        tags: tagsSelected,
        roomName: searchValue,
        agentIds: agentSelected,
        departmentId,
      };

      const [
        {
          data: { update = [] },
        },
        {
          data: { rooms = [] },
        },
        {
          data: { rooms: closedChats },
        },
      ] = await Promise.all([
        allAgentRooms ?? getAllAgentRooms(),
        getChatsFilter(propsSearchFilterOptions),
        getChatsFilter(propsSearchFilterOptionsClosedChats),
      ]);

      let filter = rooms;
      const departmentsFilter =
        departmentsSelected.length > 0
          ? departmentsSelected
          : currentUserInfo.departments.map((item) => item.departmentId);
      filter = rooms.filter((item: IRoom) => departmentsFilter.includes(item.departmentId));
      const filteredRooms = filter.reduce(
        (previous, current) => {
          if (current.open && current.servedBy) {
            previous.inAttendence.push(current);
          } else if (current.open && current.onHold) {
            previous.onHold.push(current);
          }
          return previous;
        },
        { inAttendence: [], onHold: [] } as any
      );

      setAccordeonsAllChats((oldState) =>
        oldState.map((accordeon) => {
          if (accordeon.id === 'inAttendance') {
            accordeon.rooms = filteredRooms.inAttendence.map((chat: any) => {
              const hasDiscussion = update.some((room: any) => room.prid === chat._id);

              chat.hasDiscussion = hasDiscussion;
              return chat;
            });
          } else if (accordeon.id === 'closedToday') {
            accordeon.rooms = closedChats;
          } else {
            accordeon.rooms = filteredRooms.onHold;
          }
          return accordeon;
        })
      );
      if (searchValue || currentSearchAllChats) {
        setSearchAllChats([...filter, ...closedChats]);
      } else {
        setSearchAllChats([]);
      }
      setTooltipSelectedItems([...tagsSelected, ...agentsNameSelected]);
    },
    [
      agentsNameSelected,
      currentUserInfo,
      maxChatsInAccordion,
      tagsSelected,
      agentSelected,
      departmentsSelected,
      currentSearchAllChats,
    ]
  );

  const getInquiriesChats = useCallback(async () => {
    try {
      const {
        data: { inquiries = [] },
      } = await getInquiries(maxChatsInAccordion, isFIFO ? { ts: sortValue.ASCENDING } : undefined);

      // Temporal fix while we figure out which configuration is causing `lastMessages` to be missing
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const promises = inquiries.map((inquiry: any) => {
        if (inquiry.lastMessage) {
          return inquiry;
        }

        // Queued inquiries that would be automatically assigned
        if (inquiry.estimatedWaitingTimeQueue === 0) {
          return inquiry;
        }

        return getLivechatRoomInfo(inquiry.v?.token, inquiry.rid).then((res) => ({
          ...inquiry,
          lastMessage: res.data.room.lastMessage,
        }));
      });
      /* eslint-enable @typescript-eslint/no-explicit-any */

      const roomsWithLastMessage = await Promise.all(promises);
      return roomsWithLastMessage.map(
        (item: any) =>
          ({
            _id: item.rid,
            _updatedAt: item._updatedAt,
            lastMessage: item.lastMessage,
            department: {
              _id: item.department,
            },
            open: true,
            t: item.t,
            ts: item.ts,
            v: item.v,
            departmentId: item.department,
            source: item.source,
            fname: item.name,
          } as IRoom)
      );
    } catch (error) {
      logError('Error while getting inquiries', error);
      return [];
    }
  }, [maxChatsInAccordion, isFIFO]);

  const searchChats = useCallback(
    async (searchValue?: string) => {
      try {
        let departmentId;
        if (currentUserInfo.departments.length === 1) {
          departmentId = currentUserInfo.departments[0].departmentId;
        }
        const propsSearchChats = {
          open: true,
          count: maxChatsInAccordion,
          departmentId,
          roomName: searchValue,
        };
        const {
          data: { rooms },
        } = await getChatsFilter(propsSearchChats);
        let filter = rooms;

        const departmentsFilter = currentUserInfo.departments.map((item) => item.departmentId);
        filter = rooms.filter((item) => departmentsFilter.includes(item.departmentId));
        if (searchValue || currentSearchYourChats) {
          setSearchYourChats([...filter]);
        } else {
          setSearchYourChats([]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentUserInfo, maxChatsInAccordion, currentSearchYourChats]
  );

  const handleFilterYourChats = useCallback(
    async (searchValue?: string, allAgentRooms?: any) => {
      if (!currentUserInfo._id || !currentUserInfo.departments) return;
      const userId = [currentUserInfo._id];
      if (searchValue) {
        await searchChats(searchValue);
      } else {
        const propsSearchPendingChats = {
          open: true,
          count: maxChatsInAccordion,
          tags: tagsSelectedYourChats,
          roomName: searchValue,
          agentIds: userId,
        };

        const propsSearchClosedChats = {
          open: false,
          count: maxChatsInAccordion,
          filterDateBy: DATE_FILTER_OPTIONS[1],
          tags: tagsSelectedYourChats,
          roomName: searchValue,
          agentIds: userId,
        };

        const propsSearchOnHoldChats = {
          open: true,
          count: maxChatsInAccordion,
          tags: tagsSelectedYourChats,
          roomName: searchValue,
          agentIds: userId,
          onHold: true,
        };

        const [
          pendindChats,
          {
            data: { rooms: inAttendance },
          },
          {
            data: { rooms: closedChats },
          },
          {
            data: { rooms: onHoldChats },
          },
          {
            data: { update = [] },
          },
        ] = await Promise.all([
          routingMethod === RoutingMethods.ManualSelection ? getInquiriesChats() : [],
          getChatsFilter(propsSearchPendingChats),
          getChatsFilter(propsSearchClosedChats),
          showOnHold ? getChatsFilter(propsSearchOnHoldChats) : { data: { rooms: [] } },
          allAgentRooms ?? getAllAgentRooms(),
        ]);

        pendindChats.forEach((pc: IRoom) => {
          const isSubscribedChat = subscribeChats.some((sc) => sc.rid === pc._id);
          const isSubscribedInquiry = subscribedInquiries.includes(pc._id);
          if (!isSubscribedChat && !isSubscribedInquiry) {
            setSubscribedInquiries((prev) => [...prev, pc._id]);
            listeningRoomMessages(pc._id, 'inquiry');
          }
        });

        setAccordeonsYourChats((oldState) =>
          oldState.map((accordeon) => {
            if (accordeon.id === 'inAttendance') {
              accordeon.rooms = inAttendance.map((chat: any) => {
                const oldChatState = accordeon.rooms.find((room: any) => room._id === chat._id);
                const hasDiscussion = update.some((room: any) => room.prid === chat._id);
                return { ...oldChatState, ...chat, hasDiscussion };
              });
            } else if (accordeon.id === 'pending') {
              accordeon.rooms = pendindChats;
            } else if (accordeon.id === 'closedToday') {
              accordeon.rooms = closedChats;
            } else {
              accordeon.rooms = onHoldChats;
            }
            return accordeon;
          })
        );

        setInAttendanceLength(inAttendance.length);
      }
    },
    [
      currentUserInfo._id,
      currentUserInfo.departments,
      maxChatsInAccordion,
      subscribeChats,
      subscribedInquiries,
      tagsSelectedYourChats,
      getInquiriesChats,
      listeningRoomMessages,
      searchChats,
      setInAttendanceLength,
      showOnHold,
      routingMethod,
    ]
  );

  const handleSearchChats = useCallback(
    async (menuType: string, search?: string) => {
      if (!search) {
        if (menuType === 'allChats') {
          setIsSearchAllChats(false);
          setCurrentSearchAllChats('');
        } else {
          setIsSearchYourChats(false);
          setCurrentSearchYourChats('');
        }
      } else if (menuType === 'allChats') {
        setIsSearchAllChats(true);
        setCurrentSearchAllChats(search);
      } else {
        setIsSearchYourChats(true);
        setCurrentSearchYourChats(search);
      }
      if (menuType === 'allChats') {
        handleFilterOptions(search);
      } else {
        handleFilterYourChats(search);
      }
    },
    [handleFilterOptions, handleFilterYourChats]
  );

  const handleFilterChatAndOptions = useCallback(
    async (searchValue?: string) => {
      const allAgentRooms = await getAllAgentRooms();

      handleFilterYourChats(searchValue, allAgentRooms);
      handleFilterOptions(searchValue, allAgentRooms);
    },
    [handleFilterYourChats, handleFilterOptions]
  );

  useEffect(() => {
    handleSubscribeNotification();
  }, [handleSubscribeNotification]);

  useEffect(() => {
    if (salesDeskWebsocketNotification && isSalesDeskChatMenuEnabled) {
      if (isTextNotification(salesDeskWebsocketNotification)) {
        const chatNotification = salesDeskWebsocketNotification.chat as OutgoingMessageChatMessage;
        const updatedAccordions = updateAccordions(accordeonsYourChats, chatNotification, isFIFO);
        setAccordeonsYourChats(updatedAccordions);

        if (shouldNotifyOnNewMessage(updatedAccordions, chatNotification)) {
          notify({
            type: NotificationType.NEW_MESSAGE,
            chatId: salesDeskWebsocketNotification.chat?.roomId ?? '',
            title: salesDeskWebsocketNotification.chat?.name ?? salesDeskWebsocketNotification.chat?.username ?? '',
            description: salesDeskWebsocketNotification.chat?.text?.body ?? '',
          });
        }
      }
    }
  }, [salesDeskWebsocketNotification, isSalesDeskChatMenuEnabled, isFIFO]);

  useEffect(() => {
    const hasEmptyDescription = notification?.type === NotificationType.NEW_MESSAGE && notification?.description === '';
    const triggeringFilters = isSalesDeskChatMenuEnabled ? chatMenuEnabledTriggeringFilters : typesTriggeringFilters;

    if (
      (notification && triggeringFilters.includes(notification.type)) ||
      (hasEmptyDescription && isSalesDeskChatMenuEnabled)
    ) {
      handleFilterChatAndOptions();
      getSubscribesChats();
    }

    if (notification?.title && typesTriggeringDesktopNotifications.includes(notification.type)) {
      notify(notification);
    }
  }, [notification, isSalesDeskChatMenuEnabled]);

  useEffect(() => {
    if (currentUserInfo._id) {
      handleFilterYourChats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo._id, currentUserInfo.departments, tagsSelectedYourChats, routingMethod]);

  useEffect(() => {
    handleFilterOptions();
  }, [handleFilterOptions]);

  useEffect(() => {
    if (serviceSelected?._id && websocketIsConnect) {
      loadHistoryMessages(serviceSelected._id, 'chat');
      listeningRoomMessages(serviceSelected._id, 'chat');
      handleActiveInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceSelected?._id, websocketIsConnect]);

  useEffect(() => {
    if (subscribeNotification && subscribeNotification.onHold === undefined) {
      setAccordeonsYourChats((oldState) =>
        oldState.map((accordeon) => {
          accordeon.rooms.map((room: any) => {
            if (subscribeNotification.rid === room._id || subscribeNotification._id === room._id) {
              if (subscribeNotification.alert !== undefined && subscribeNotification.unread !== undefined) {
                room.unreadChat = subscribeNotification.alert;
                room.unreadMessages = subscribeNotification.unread;
              }

              if (subscribeNotification.lastMessage) {
                room.lastMessage = subscribeNotification.lastMessage;
              }
            }
            return room;
          });
          return accordeon;
        })
      );
    } else if (subscribeNotification && typeof subscribeNotification.onHold === 'boolean') {
      handleFilterChatAndOptions();
      getSubscribesChats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeNotification]);

  /**
   * Update chat subscription when the chatId changes
   */
  useEffect(() => {
    getSubscribesChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (reload && searchAllChats.length === 0 && searchYourChats.length === 0) {
      handleFilterChatAndOptions();
      handleReload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, searchAllChats.length, searchYourChats.length]);

  useEffect(() => {
    setAccordeonsYourChats((oldState) =>
      oldState.map((item) => {
        if (item.id === 'inAttendance') {
          item.title = t('inAttendance');
        } else if (item.id === 'pending') {
          item.title = t('pending');
        } else if (item.id === 'closedToday') {
          item.title = t('closedToday');
        } else {
          item.title = t('onHold');
        }
        return item;
      })
    );
    setAccordeonsAllChats((oldState) =>
      oldState.map((item) => {
        if (item.id === 'inAttendance') {
          item.title = t('inAttendance');
        } else if (item.id === 'pending') {
          item.title = t('pending');
        } else if (item.id === 'closedToday') {
          item.title = t('closedToday');
        } else {
          item.title = t('onHold');
        }
        return item;
      })
    );
  }, [t]);

  const memoizedValuesMenuChatsProvider = useMemo(
    () => ({
      accordeonsAllChats,
      accordeonsYourChats,
      handleExpand,
      setTagsSelected,
      setAgentSelected,
      setDepartmentsSelected,
      handleSelectedItems,
      agentSelected,
      tagsSelected,
      departmentsSelected,
      isSelectedItems,
      tooltipSelectedItems,
      setAgentsNameSelected,
      setTagsSelectedYourChats,
      tagsSelectedYourChats,
      handleSearchChats,
      searchYourChats,
      searchAllChats,
      isSearchYourChats,
      isSearchAllChats,
      handleReload,
      openContactManager,
      setOpenContactManager,
      openChatsManager,
      setOpenChatsManager,
      openDrawerContactManager,
      setOpenDrawerContactManager,
      openServiceDashboard,
      setOpenServiceDashboard,
    }),
    [
      accordeonsAllChats,
      accordeonsYourChats,
      handleExpand,
      setTagsSelected,
      setAgentSelected,
      setDepartmentsSelected,
      handleSelectedItems,
      agentSelected,
      tagsSelected,
      departmentsSelected,
      isSelectedItems,
      tooltipSelectedItems,
      setAgentsNameSelected,
      setTagsSelectedYourChats,
      tagsSelectedYourChats,
      handleSearchChats,
      searchYourChats,
      searchAllChats,
      isSearchYourChats,
      isSearchAllChats,
      handleReload,
      openContactManager,
      setOpenContactManager,
      openChatsManager,
      setOpenChatsManager,
      openDrawerContactManager,
      setOpenDrawerContactManager,
      openServiceDashboard,
      setOpenServiceDashboard,
    ]
  );

  return <MenuChatsContext.Provider value={memoizedValuesMenuChatsProvider}>{children}</MenuChatsContext.Provider>;
};

export default MenuChatsProvider;
